<template>
  <section
    class="invoice-preview-wrapper"
    style="position: relative"
  >
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="order === undefined"
    >
      <h4 class="alert-heading">
        Error fetching invoice data
      </h4>
      <div class="alert-body">
        No invoice found with this invoice id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'facture-list' }"
        >
          invoice List
        </b-link>
        for other invoice.
      </div>
    </b-alert>

    <b-row
      v-if="order"
      class="invoice-preview"
    >
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          v-if="order"
          no-body
          class="invoice-preview-card"
        >

          <div
            class="d-flex justify-content-between flex-wrap mr-1 ml-1"
          >

            <div class="invoice-preview">
              <h1
                class="mt-1"
                style="color: #E80000; font-size: 30px"
              >
                Bon de livraison
                <span

                  class="invoice-title invoice-number"
                  style="color: #E80000;"
                >
                  N° : BL-{{ order.id }}
                </span>
              </h1>
              <h3 style="color: black ">
                Date:
                <span
                  class="invoice-date"
                  style="color: black"
                >
                  {{ formatFn(order.created_at) }}</span>
              </h3>
              <div class="d-flex justify-content-between mt-1">
                <div
                  v-if="order.external_id"
                  class="mx-2"
                >
                  <qrcode-vue
                    :value="order.external_id.toString()"
                    :size="size"
                    level="H"
                  />
                  <span>{{ order.external_id }}</span>
                </div>
                <div
                  v-if="order.external_id"
                  class="mx-2"
                >
                  <barcode :value="order.external_id.toString()" />
                </div>
              </div>
            </div>

            <div style="text-align:end">
              <div>
                <img
                  src="@/assets/images/logo/logo.png"
                  width="250px"
                  height="100%"
                >

              </div>
              <div class="mt-1">
                <b-card-text
                  class="h4"
                  style="color: black"
                >
                  <strong
                    style="color:#E80000"
                  >{{ company.name }} :</strong> Société de livraison des colis
                </b-card-text>
                <b-card-text
                  class="h4"
                  style="color: black"
                >
                  <strong
                    style="color:#E80000"
                  >Matricule fiscale :</strong> {{ company.tax_identification_number ? '***' + company.tax_identification_number.slice(3)
                    : '' }}
                </b-card-text>
                <b-card-text
                  class="h4"
                  style="color: black"
                >
                  <strong
                    style="color:#E80000"
                  >Adresse :</strong> {{ company.address }}
                </b-card-text>
                <b-card-text
                  class="h4"
                  style="color: black"
                >
                  <strong
                    style="color:#E80000"
                  >Tel :</strong> {{ company.phone1 }}
                </b-card-text>

              </div>

            </div>
          </div>
          <b-card-text
            v-if="order.is_an_exchange ==true"
            class="h4 ml-1 text-center p-1"
            style="color:black;font-size: 25px; border:1px solid black"
          >
            <strong style="color:#E80000">Échange</strong>
          </b-card-text>
          <b-card-text
            v-if="order.is_fragile ==true"
            class="h1 mx-1 text-center p-1"
            style="color:black;font-size: 50px; border:2px solid black"
          >
            <strong style="color:#BC2B18">Fragile</strong>
          </b-card-text>
          <div class="d-flex flex-row justify-content-between mr-1 ml-1 mb-1">

            <div>

              <div
                style="border: 2px solid black;font-size: 26px;"
              >
                <b-card-text class="ml-2 mr-2 mt-2 mb-1 font-weight-bold">
                  <span
                    style="font-weight:900; color: #E80000"
                  >Client : </span> {{ order.name }}
                </b-card-text>
                <b-card-text class="ml-2 mr-2 mb-1">
                  <span
                    style="font-weight:900; color: #E80000"
                  >Gouvernorat : </span>{{ order.governorate.name }} <span v-if="order.delegation != null"> ,{{ order.delegation.name }}</span> <span v-if="order.locality"> ,{{ order.locality }}</span>
                </b-card-text>

                <b-card-text class="ml-2 mr-2 mb-1">
                  <span style="font-weight:900; color: #E80000">Adresse :</span>
                  {{ order.address ? (order.address.length > 40 ? order.address.slice(0, 40) + '...' : order.address) : '' }}
                </b-card-text>
                <b-card-text class="ml-2 mr-2 mb-1">
                  <span
                    style="font-weight:900; color: #E80000"
                  > téléphone :</span>
                  {{ order.phone1 }} / {{ order.phone2 }}
                </b-card-text>
              </div>
            </div>
            <div>

              <div
                class="ml-2"
                style="border: 2px solid black;font-size: 14px;"
              >

                <b-card-text class="ml-2 mr-2 mt-2 mb-1 font-weight-bold">
                  <span
                    style="font-weight:900; color: #E80000"
                  >Fournisseur :</span> {{ order.supplier.username }}
                </b-card-text>
                <b-card-text class="ml-2 mr-2 mb-1">
                  <span
                    style="font-weight:900; color: #E80000"
                  >Téléphone : </span>{{ order.supplier.phone }} / {{ order.supplier.phone2 }}
                </b-card-text>
                <b-card-text class="ml-2 mr-2 mb-1">
                  <span
                    style="font-weight:900; color: #E80000"
                  >Adresse :</span>
                  {{ order.supplier.address }}
                </b-card-text>
                <b-card-text class="ml-2 mr-2 mb-1">
                  <span style="font-weight:900; color: #E80000">Matricule Fiscale :</span>
                  {{
                    order.supplier.tax_identification_number
                      ? '***' + order.supplier.tax_identification_number.slice(3)
                      : ''
                  }}
                </b-card-text>
              </div>
            </div>

          </div>

          <!-- Invoice Description: Table -->
          <div class="mr-1 ml-1">
            <b-table-lite
              class="ta"
              style="font-size: 20px; border: 2px solid black"
              responsive
              :items="p"
              :fields="['désignation', 'quantité']"
            >
              <template #cell(taskDescription)="data">
                <b-card-text class="font-weight-bold mb-25">
                  {{ data.item.taskTitle }}
                </b-card-text>
                <b-card-text class="text-nowrap">
                  {{ data.item.taskDescription }}
                </b-card-text>
              </template>
            </b-table-lite>
          </div>
          <!-- Invoice Description: Total -->
          <div class="mr-1 ml-1">
            <b-row>
              <b-col>
                <h1
                  style="color: #E80000; font-size: 18px ; font-weight: bold"
                >
                  Commentaire :
                  <span
                    style="font-size: 15px ; color:black;"
                  >
                    {{ order.note }}
                  </span>
                </h1>
                <p v-if="order.external_supplier_name && order.external_supplier_phone">
                  Fournisseur Externe: {{ order.external_supplier_name }} - {{ order.external_supplier_phone }}
                </p>
                <div>

                  <b-card-text
                    v-if="order.is_fragile ==true"
                    class="h4"
                    style="color:black"
                  >
                    <strong style="color:#E80000">Fragile :</strong> OUI
                  </b-card-text>
                  <b-card-text
                    v-if="order.is_discharge == true"
                    class="h4"
                    style="color:black"
                  >
                    <strong style="color:#E80000">Décharge :</strong> OUI
                  </b-card-text>
                  <br>
                  <div class="mx-2">
                    <qrcode-vue
                      :value="order.id.toString()"
                      :size="size"
                      level="H"
                    />
                    <span>{{ order.id }}</span>
                  </div>
                </div>

              </b-col>
              <b-col
                class="ml-2"
                style="font-size: 20px"
              >
                <table
                  style="border: 2px solid #E80000"
                >
                  <tr class="badge-glow bg-info bg-lighten-5">
                    <th class="text-center">
                      Montant HT: {{ order.price_ht }}
                    </th>
                  </tr>
                  <tr class="badge-glow bg-primary bg-lighten-5"><th class="text-center">TVA 7 %: {{ order.tax }}
                  </th></tr>
                  <tr class="badge-glow bg-success bg-lighten-1"> <th class="text-center">Total TTC: {{ order.price_ttc }} DT
                  </th></tr>

                </table>
              </b-col>

            </b-row>
          </div>

        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Imprimer
          </b-button>

          <!-- Button: Edit -->
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import storeAuth from '@/store/store'
import QrcodeVue from 'qrcode.vue'
import VueBarcode from 'vue-barcode'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    QrcodeVue,
    barcode: VueBarcode,
  },
  data() {
    return {
      size: 80,
      p: [],
      order: [],
      company: {},
    }
  },

  created() {
    this.id = this.$route.params.id
    this.company = storeAuth.state.company
    this.getOrder()
  },
  methods: {
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(
        2,
        '0',
      )} | ${h}`
    },
    formatDate(date) {
      if (!date) {
        return null
      }
      const [year] = date.split('-')
      return `${year.slice(2, 4)}`
    },
    parseDate(date) {
      if (!date) {
        return null
      }
      const [month, day, year] = date.split('/')
      return `${year.padStart(0, '0')}-${month.padStart(2, '0')}-${day.padStart(
        2,
        '0',
      )}`
    },
    addnewitem(qte, nom) {
      this.p.push({
        désignation: nom,
        quantité: qte,
      })
    },
    async getOrder() {
      await axios
        .get(`/api/orders/detail/${this.$route.params.id}`)
        .then(response => {
          this.order = response.data
        })
      if (this.order != null) {
        for (let i = 0; i < this.order.products.length; i += 1) {
          this.addnewitem(this.order.products[i].quantity, this.order.products[i].product)
        }
      }
    },
  },
  setup() {
    const printInvoice = () => {
      window.print()
    }

    return {
      printInvoice,

    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
table,
td,
th {
  padding: 10px;
  text-align: left;
  border: 1px solid #E80000;
}

table {
  width: 100%;
  border-collapse: collapse;
}
.mycolor {
  background-color: #EA2F2F;
}
.contact {
  background-color: #EA2F2F;
  padding: 10px;
  border-radius: 20px;
}
</style>

<style lang="scss">
@media print {
  html, body {
    height: 99%;
  }
.ta {
    white-space: nowrap;
    overflow-x: visible;
    font-size: 22px;
    color: black;
  }
  footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: auto; /* Footer height */
  }

  .table thead th {
    border-bottom: 2px solid black;
  }

  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  .mycolor {
    background-color: #EA2F2F;
    -webkit-print-color-adjust: exact;
  }
  .contact {
    background-color: #EA2F2F;
    padding: 20px;
    border-radius: 25px;
    -webkit-print-color-adjust: exact;
  }
  footer footer-light footer-static {
    display: inline-block;
  }
  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
  table,
  td,
  th {
    padding: 10px;
    text-align: left;
    border: 1px solid black;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
}
</style>
